import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
const confirmaccesrequest = () => (
  <Layout>
    <SEO
              title={`Confirmation demande d accès`}
              description="Confirmation demande d accès"/>
    <div style={{textAlign:'center'}}>
      <h1>Votre demande nous a bien été transmise</h1>
      <h1>Vous recevrez prochainement un e-mail avec vos identifiants</h1>
      <h1>Merci pour l’intérêt porté à nos produits</h1>
    </div>
  </Layout>
)

export default confirmaccesrequest
